<template>
  <div class="month">
    <div class="wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div data-v-7e5084da="" class="reshim">
              <p data-v-7e5084da="" class="reshim_title">Решим ваш вопрос </p>
              <p data-v-7e5084da="" class="reshim_titleBox"><b data-v-7e5084da="" class="reshim_titleBox_green">бесплатно</b> уже сегодня</p>
              <p data-v-7e5084da="" class="reshim_info">и подарим месяц </p>
              <p data-v-7e5084da="" class="reshim_infotwo">юридического обслуживания </p>
              <p data-v-7e5084da="" class="reshim_infothree"> вашей компании</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="body">
      <div style="margin-bottom: 20px" class="container">
        <div class="vxod">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="row">
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">ЮРИДИЧЕСКИЕ КОНСУЛЬТАЦИИ</h1>
                      <p class="yuridik_box_info">Письменные, устные - без ограничений</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">АВТОДЕЛА</h1>
                      <p class="yuridik_box_info">
                        - Возврат прав<br>
                        - Претензия страховщику<br>
                        - Оспорить перегруз<br>
                        - Оспорить штраф с камеры
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">ПОДГОТОВКА ДОКУМЕНТОВ</h1>
                      <p class="yuridik_box_info">Жалобы, заявления, претензии, ходайства, ответы, отзывы и много чего еще</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">НАЛОГОВЫЕ и ПФР СПОРЫ</h1>
                      <p class="yuridik_box_info">
                        - Ответы на требования<br>
                        - Оспаривание решений госорганов</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title"> НУЖНО ЧТО ТО ЕЩЕ?</h1>
                      <p class="yuridik_box_info">Напишите нам! Обсудим и согласуем
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="row">
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">АРБИТРАЖ</h1>
                      <p class="yuridik_box_info">Составить<br>
                        - Исковое заявление<br>
                        - Подготовить<br>
                        - Отзыв на исковое заявление<br>
                        - Иск в третейский суд</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">ДОГОВОРЫ</h1>
                      <p class="yuridik_box_info">
                        - Анализ договора<br>
                        - Ответ на протокол разногласий<br>
                        - Составить договор</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">ТРУДОВЫЕ СПОРЫ </h1>
                      <p class="yuridik_box_info">
                        - Ответы на претензии<br>
                        - Ответы госинстанциям</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="yuridik">
                    <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                    <div class="yuridik_box">
                      <h1 class="yuridik_box_title">ПРИСТАВЫ</h1>
                      <p class="yuridik_box_info">
                        - Заявление на исполнительный лист<br>
                        - Жалоба на судебных приставов<br>
                        - Приостановление исполнения<br>
                        - Вывод имущества</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form/>
    </section>
  </div>
</template>

<script>
import Form from "@/components/Form";
export default {
  name: "Month",
  components: {Form}
}
</script>

<style scoped lang="scss">
@import "../assets/css/month.css";
@import "../assets/css/monthMEdia.css";
.wrap {
  width: 100%;
  background: url("../assets/images/back.png");
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  color: white!important;
  .reshim {
    padding-top: 0;
  }
}
.body {
  padding: 0;
  padding-top: 10px;
}
.vxod {
  padding-top: 0;
  @media (max-width: 700px) {
    padding-bottom: 100px;
  }
}
</style>
