<template>
  <div class="admin">
    <v-notification :messages="notification" :timeout="3000"/>
    <h1
        class="admin-head"
    >
      Панель управления
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M21.7 18.6V17.6L22.8 16.8C22.9 16.7 23 16.6 22.9 16.5L21.9 14.8C21.9 14.7 21.7 14.7 21.6 14.7L20.4 15.2C20.1 15 19.8 14.8 19.5 14.7L19.3 13.4C19.3 13.3 19.2 13.2 19.1 13.2H17.1C16.9 13.2 16.8 13.3 16.8 13.4L16.6 14.7C16.3 14.9 16.1 15 15.8 15.2L14.6 14.7C14.5 14.7 14.4 14.7 14.3 14.8L13.3 16.5C13.3 16.6 13.3 16.7 13.4 16.8L14.5 17.6V18.6L13.4 19.4C13.3 19.5 13.2 19.6 13.3 19.7L14.3 21.4C14.4 21.5 14.5 21.5 14.6 21.5L15.8 21C16 21.2 16.3 21.4 16.6 21.5L16.8 22.8C16.9 22.9 17 23 17.1 23H19.1C19.2 23 19.3 22.9 19.3 22.8L19.5 21.5C19.8 21.3 20 21.2 20.3 21L21.5 21.4C21.6 21.4 21.7 21.4 21.8 21.3L22.8 19.6C22.9 19.5 22.9 19.4 22.8 19.4L21.7 18.6M18 19.5C17.2 19.5 16.5 18.8 16.5 18S17.2 16.5 18 16.5 19.5 17.2 19.5 18 18.8 19.5 18 19.5M11 18C11 14.1 14.1 11 18 11C20 11 21.7 11.8 23 13.1V4C23 2.9 22.1 2 21 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H12.3C11.5 20.9 11 19.5 11 18M3 4H21V7H3V4Z" />
      </svg>
    </h1>

    <h2 @click="folding.main = !folding.main">Главная страница
      <svg v-if="!folding.main" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
      </svg>
      <svg v-if="folding.main" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
      </svg>
    </h2>
    <transition name="fade">
      <div class="admin-container" v-if="folding.main">
        <div class="main">
          <h5>Блок вопросов (можно писать теги)</h5>
          <div class="screen-block">
            <img src="../assets/images/admin/Screenshot_1.png" alt="">
          </div>
          <div class="table-container">
            <table class="vue-table questBlock" v-if="getDatabase.home">
              <thead>
              <tr>
                <th v-for="(col, i) in questBlock"
                    :key="i"
                >{{ col.label }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, i) in getDatabase.home.questBlock" :key="i">
                <td
                    v-for="(col, index) in questBlock"
                    :key="index"
                    @click="edit($event)"
                    @focusout.prevent="editSave($event, i, col.prop, 'questBlock')"
                    @keydown.enter.prevent="editSave($event, i, col.prop, 'questBlock')"
                    @keydown.esc.prevent="editSave($event, i, col.prop, 'questBlock')"
                >{{row[col.prop]}}
                  <span
                      v-if="col.prop === 'subtitle'"
                      class="delete-block"
                      title="Удалить"
                      @click="getDatabase.home.questBlock.splice(i,1)"
                  >
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="plus-element" @click="addQuest('questBlock')">
              <svg style="width:44px;height:44px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="business">
          <h5>Блок "ЗАНИМАЙТЕСЬ БИЗНЕСОМ"</h5>
          <div class="screen-block">
            <img src="../assets/images/admin/Screenshot_2.png" alt="">
          </div>
          <div class="table-container">
            <table class="vue-table business" v-if="getDatabase.home">
              <thead>
              <tr>
                <th v-for="(col, i) in business"
                    :key="i"
                >{{ col.label }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, i) in getDatabase.home.business" :key="i">
                <td
                    v-for="(col, index) in business"
                    :key="index"
                    @click="edit($event)"
                    @focusout.prevent="editSave($event, i, col.prop, 'business')"
                    @keydown.enter.prevent="editSave($event, i, col.prop, 'business')"
                    @keydown.esc.prevent="editSave($event, i, col.prop, 'business')"
                >{{row[col.prop]}}
                  <span
                      v-if="col.prop === '4'"
                      class="delete-block"
                      title="Удалить"
                      @click="getDatabase.home.business.splice(i,1)"
                  >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                </svg>
            </span>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="plus-element" @click="addQuest('business')">
              <svg style="width:44px;height:44px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <h2 @click="folding.articles = !folding.articles">Статьи
      <svg v-if="!folding.articles" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
      </svg>
      <svg v-if="folding.articles" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
      </svg>
    </h2>
    <transition name="fade">
      <div v-if="folding.articles && getDatabase">
        <div class="admin-article-container" v-for="(article, i) in getDatabase.articles" :key="'article-'+i">
          <div @click="article.showList = !article.showList" class="admin-article-name">
            {{"Статья: " + article.name}}
            <svg v-if="!article.showList" style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
            </svg>
            <svg v-if="article.showList" style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
            </svg>
            <span style="float: right;">
              <span
                  class="delete-block"
                  title="Удалить"
                  @click="getDatabase.articles.splice(i,1)"
              >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                </svg>
              </span>
            </span>
          </div>
          <div v-if="article.showList" class="admin-article">
            <div class="admin-article-text">
              <label>Название статьи: <textarea type="text" class="admin-article-text-name" v-model="article.name"/></label>
              <label>Текст статьи: <textarea type="text" v-model="article.text"/></label>
            </div>
            <div class="admin-article-image">
              <p>Изображение статьи</p>
              <img :src="article.image" alt="">
              <span class="image-meta">
                <label class="upload-label">
                  <h5 class="filename"></h5>
                  <input @change="upload($event, 'articles', i)" type="file" class="file" name="">
                  <span class="upload-file-label">Изменить</span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="admin-article-add">
          <div class="plus-element" @click="addQuest('articles')">
            <svg style="width:44px;height:44px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
            </svg>
          </div>
        </div>
      </div>
    </transition>

    <h2 @click="folding.about = !folding.about">О нас
      <svg v-if="!folding.about" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
      </svg>
      <svg v-if="folding.about" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
      </svg>
    </h2>
    <transition name="fade">
      <div v-if="folding.about && getDatabase">
        <div class="admin-about-container" v-for="(about, i) in getDatabase.about" :key="'about-'+i">
          <div @click="about.showList = !about.showList" class="admin-about-name">
            {{about.name}}
            <svg v-if="!about.showList" style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
            </svg>
            <svg v-if="about.showList" style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
            </svg>
          </div>
          <div v-if="about.showList" class="admin-about">
            <slot v-if="0 === i">
              <p style="color: gray; margin-bottom: 10px">Изображение должно быть 211px\211px</p>
              <VueSlickCarousel v-bind="settings">
                <div class="item" v-for="(lawyer, index) in about.data" :key="'about-lawyer'+index">
                  <div class="lawyer-avatar">
                    <img :src="lawyer.image" class="item_img" alt="404">
                    <span class="image-meta">
                    <label class="upload-label">
                      <h5 class="filename"></h5>
                      <input @change="upload($event, 'lawyer', index)" type="file" class="file" name="">
                      <span class="upload-file-label">Изменить</span>
                    </label>
                  </span>
                  </div>
                  <input type="text" v-model="lawyer.name">
                  <input type="text" v-model="lawyer.info">
                  <button
                      title="Удалить"
                      @click.prevent="about.data.splice(index,1)"
                      style="position: absolute"
                  >удалить</button>
                </div>
              </VueSlickCarousel>
              <div class="admin-article-add" style="margin-bottom: 20px">
                <div class="plus-element" @click="addQuest('lawyer')">
                  <svg style="width:44px;height:44px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
                  </svg>
                </div>
              </div>
            </slot>
            <slot v-if="1 === i">
              <p style="color: gray; margin-bottom: 10px">Изображение должно быть 211px\298px</p>
              <VueSlickCarousel v-bind="settings">
                <div class="item" v-for="(achievements, index) in about.data" :key="'about-achievements'+index">
                  <div class="lawyer-avatar">
                    <img :src="achievements.image" class="item_img" alt="404">
                    <span class="image-meta">
                    <label class="upload-label">
                      <h5 class="filename"></h5>
                      <input @change="upload($event, 'achievements', index)" type="file" class="file" name="">
                      <span class="upload-file-label">Изменить</span>
                    </label>
                  </span>
                  </div>
                  <button
                      title="Удалить"
                      @click.prevent="about.data.splice(index,1)"
                      style="position: absolute"
                  >удалить</button>
                </div>
              </VueSlickCarousel>
              <div class="admin-article-add" style="margin-bottom: 20px">
                <div class="plus-element" @click="addQuest('achievements')">
                  <svg style="width:44px;height:44px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
                  </svg>
                </div>
              </div>
            </slot>
            <slot v-if="2 === i">
              <p style="color: gray; margin-bottom: 10px">Изображение должно быть 200px\126px</p>
              <VueSlickCarousel v-bind="settings">
                <div class="item" v-for="(weekdays, index) in about.data" :key="'about-weekdays'+index">
                  <div class="lawyer-avatar">
                    <img :src="weekdays.image" class="item_img" alt="404">
                    <span class="image-meta">
                    <label class="upload-label">
                      <h5 class="filename"></h5>
                      <input @change="upload($event, 'weekdays', index)" type="file" class="file" name="">
                      <span class="upload-file-label">Изменить</span>
                    </label>
                  </span>
                  </div>
                  <button
                      title="Удалить"
                      @click.prevent="about.data.splice(index,1)"
                      style="position: absolute"
                  >удалить</button>
                </div>
              </VueSlickCarousel>
              <div class="admin-article-add" style="margin-bottom: 20px">
                <div class="plus-element" @click="addQuest('weekdays')">
                  <svg style="width:44px;height:44px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z" />
                  </svg>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>

    <h2 @click="folding.footer = !folding.footer">Подвал
      <svg v-if="!folding.footer" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
      </svg>
      <svg v-if="folding.footer" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
      </svg>
    </h2>
    <transition name="fade">
      <div class="admin-container" v-if="folding.footer && getDatabase">
        <h5>Ссылки соц.сети</h5>
        <div class="url-social">
          <label><img src="../assets/icons/vk.svg" class="foot_two_network_vk" alt="404"> <p>Вконтакте</p> <input v-model="getDatabase.footer.link.vk" type="text"></label>
          <label><img src="../assets/icons/facebok.svg" class="foot_two_network_fc" alt="404"> <p>Фейсбук</p> <input v-model="getDatabase.footer.link.fb" type="text"></label>
          <label><img src="../assets/icons/instagram.svg" class="foot_two_network_in" alt="404"> <p>Инстаграм</p> <input v-model="getDatabase.footer.link.inst" type="text"></label>
        </div>
        <h5>Ссылки онлайн-сервисы</h5>
        <div class="url-social" style="width: 400px">
          <label><p>Онлайн программы и интенсивы для решения юридических вопросов</p> <input v-model="getDatabase.footer.services.programs" type="text" placeholder="ссылка"></label>
          <label><p>Шаблоны документов по всем юридическим вопросам</p> <input v-model="getDatabase.footer.services.templates" type="text" placeholder="ссылка"></label>
        </div>
      </div>
    </transition>

    <h2 @click="folding.footer = !folding.footer">Подвал
      <svg v-if="!folding.footer" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />
      </svg>
      <svg v-if="folding.footer" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,16V14.5L12,8.5L6,14.5V16H18M12,11.33L14.67,14H9.33L12,11.33Z" />
      </svg>
    </h2>
    <transition name="fade">
      <div class="admin-container" v-if="folding.footer && getDatabase">
        <h5>Ссылки соц.сети</h5>
        <div class="url-social">
          <label><img src="../assets/icons/vk.svg" class="foot_two_network_vk" alt="404"> <p>Вконтакте</p> <input v-model="getDatabase.footer.link.vk" type="text"></label>
          <label><img src="../assets/icons/facebok.svg" class="foot_two_network_fc" alt="404"> <p>Фейсбук</p> <input v-model="getDatabase.footer.link.fb" type="text"></label>
          <label><img src="../assets/icons/instagram.svg" class="foot_two_network_in" alt="404"> <p>Инстаграм</p> <input v-model="getDatabase.footer.link.inst" type="text"></label>
        </div>
        <h5>Ссылки онлайн-сервисы</h5>
        <div class="url-social" style="width: 400px">
          <label><p>Онлайн программы и интенсивы для решения юридических вопросов</p> <input v-model="getDatabase.footer.services.programs" type="text" placeholder="ссылка"></label>
          <label><p>Шаблоны документов по всем юридическим вопросам</p> <input v-model="getDatabase.footer.services.templates" type="text" placeholder="ссылка"></label>
        </div>
      </div>
    </transition>

    <div class="admin-block-save">
      <a @click="writeDB($root.database)" class="btn" href="#">Сохранить изменения</a>
      <a @click="LOGOUT" class="btn" href="#">Выйти</a>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VNotification from "@/components/notifications/v-notification";
import readDB from "@/components/read";
import {mapActions} from "vuex";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
export default {
  name: "AdminPanel",
  components: {VNotification,VueSlickCarousel},
  data () {
    return {
      questBlock: [
        { label: 'Заголовок', prop: 'title', type: 'string' },
        { label: 'Позаголовок', prop: 'subtitle', type: 'string' },
      ],
      business: [
        { label: '', prop: '1', type: 'string' },
        { label: 'Обслуживание у нас', prop: '2', type: 'string' },
        { label: 'Штатный юрист', prop: '3', type: 'string' },
        { label: 'Фрилансер', prop: '4', type: 'string' },
      ],
      folding: {
        main: false,
        articles: false,
        about: false,
        footer: false,
      },
      notification: [],
      settings: {
        "dots": false,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 5,
        "slidesToScroll": 2,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    }
  },
  computed: {
    getDatabase () {
      return this.$root.database
    }
  },
  methods: {
    ...mapActions([
      'LOGOUT'
    ]),
    edit (val) {
      val.target.contentEditable = 'true'
      val.target.focus()
      val.target.style.border = '1px solid black'
    },
    editSave (val, index, prop, key) {
      val.target.contentEditable = 'false'
      val.target.style.border = '1px solid #ccc'
      val.target.style.borderLeft = '0'
      val.target.style.borderTop = '0'
      if (key === 'questBlock') this.getDatabase.home.questBlock[index][`${prop}`] = val.target.innerText
      if (key === 'business') this.getDatabase.home.business[index][`${prop}`] = val.target.innerText
    },
    addQuest (key) {
      if (key === 'questBlock') this.getDatabase.home.questBlock.push( { title: 'Заголовок', subtitle: 'Подзаголовок' } )
      if (key === 'business') this.getDatabase.home.business.push( { 1: '111', 2: '222', 3: '333', 4: '444' } )
      if (key === 'articles') this.getDatabase.articles.push( {
        name: "Новая статья",
        subtitle: "subtitle",
        showList: false,
        text: "Текст статьи",
        image: "",
        id: this.getDatabase.articles.length + 1
      } )
      if (key === 'lawyer') this.getDatabase.about[0].data.push( {
        image: "",
        info: "",
        name: ""
      } )
      if (key === 'achievements') this.getDatabase.about[1].data.push( {
        image: "",
      } )
      if (key === 'weekdays') this.getDatabase.about[2].data.push( {
        image: "",
      } )
    },
    writeDB (data) {
      readDB.writeUserData(data).then(() => {
        this.notification.unshift(
            { name: 'Сохранено!', icon: 'check_circle', id: 1 }
        )
      }, () => {
        this.notification.unshift(
            { name: 'Ошибка!', icon: 'error', id: 2 }
        )
      })
    },
    upload (event, key, index) {
      const file = event.currentTarget.files[0]
      const storage = getStorage();

      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: 'image/jpeg'
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, 'images/' + Math.floor(9 + Math.random() * (9999 + 1 - 5)) + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

                // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              if (key === 'articles') {
                if (this.getDatabase.articles[index].image) {
                  deleteObject(ref(storage, this.getDatabase.articles[index].image)).then(() => {
                    console.log('File deleted successfully')
                  }).catch((error) => {
                    console.log('Uh-oh, an error occurred!')
                  });
                }

                this.getDatabase.articles[index].image = downloadURL
              }
              if (key === 'lawyer') {
                if (this.getDatabase.about[0].data[index].image) {
                  deleteObject(ref(storage, this.getDatabase.about[0].data[index].image)).then(() => {
                    console.log('File deleted successfully')
                  }).catch((error) => {
                    console.log('Uh-oh, an error occurred!')
                  });
                }

                this.getDatabase.about[0].data[index].image = downloadURL
              }
              if (key === 'achievements') {
                if (this.getDatabase.about[1].data[index].image) {
                  deleteObject(ref(storage, this.getDatabase.about[1].data[index].image)).then(() => {
                    console.log('File deleted successfully')
                  }).catch((error) => {
                    console.log('Uh-oh, an error occurred!')
                  });
                }

                this.getDatabase.about[1].data[index].image = downloadURL
              }
              if (key === 'weekdays') {
                if (this.getDatabase.about[2].data[index].image) {
                  deleteObject(ref(storage, this.getDatabase.about[2].data[index].image)).then(() => {
                    console.log('File deleted successfully')
                  }).catch((error) => {
                    console.log('Uh-oh, an error occurred!')
                  });
                }
                this.getDatabase.about[2].data[index].image = downloadURL
              }
            });
          }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
$color: #c61515;
.admin {
  padding: 20px;
  .admin-head {
    display: table;
    margin: 20px auto;
  }
  h2 { color: gray; margin: 20px 0; display: table; cursor: pointer }
  h5 { margin: 10px 0 }
  .admin-block-save {
    position: fixed; bottom: 50px; right: 20px;display: flex;
    z-index: 2000;
    .btn {
      opacity: .5;
      &:hover {
        opacity: 1;
        background: #3ecf31;
        color: white;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 10px;
        border: initial;
        background: #868686;
      }
    }
  }

  .image-meta {
    content: '';
    width: 100px;
    height: 50px;
    background: white;
    position: absolute;
    top: calc(50% - 0px);
    left: calc(50% - 45px);
    z-index: 200;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
  }

  .admin-article-container {
    padding: 0 20px;
    border-bottom: 1px solid gray;
    .admin-article-name {
      padding: 20px 0 10px 20px;
      border-radius: 20px 20px 0 0;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        background: #eaeaea;
      }
    }
    .admin-article {
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .admin-article-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-weight: bold;
          textarea {
            width: 100%;
            max-width: 700px;
            height: 200px;
            padding: 10px;
          }
          .admin-article-text-name {
            height: 75px;
          }
        }
      }
      .admin-article-image {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        position: relative;
        margin-bottom: 20px;
        p {
          margin: 20px 0 10px 0;
        }
        img {
          width: 100%;
          max-width: 500px;
        }
      }
    }
  }
  .admin-article-add {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    &:hover {
      cursor: pointer;
      &:before {
        content: 'Добавить';
        width: 70px;
        height: 40px;
        position: absolute;
        bottom: -30px;
        left: calc(50% - 35px);
      }
    }
  }

  .admin-about-container {
    padding: 0 20px;
    border-bottom: 1px solid gray;
    .admin-about-name {
      padding: 20px 0 10px 20px;
      border-radius: 20px 20px 0 0;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        background: #eaeaea;
      }
    }
    .admin-about {
      .item {
        padding: 0 10px;
        display: grid !important;
        grid-template-rows: 14em 2em 2em 2em;
        .lawyer-avatar {
          display: grid !important;
          grid-template-rows: 4em 6em;
          position: relative;
          img {
            max-width: 206px;
          }
        }
        input {
          border: none;
          border-bottom: 1px solid gray;
          padding: 0 5px;
          margin: 5px 0;
          background: none;
        }
      }
    }
  }

  .admin-container {
    padding: 20px;
  }

  .url-social {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 320px;
    input { max-height: 40px; border: none; border-bottom: 1px solid gray; background: none }
    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    img {
      max-width: 100px;
    }
  }
  .screen-block {
    img {
      max-width: 500px;
    }
  }
  .table-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 90%;
    margin-bottom: 40px;
    .delete-block {
      margin-left: auto;
      transform: translate(50px,0);
      width: 40px;
      height: 40px;
      padding: 7px;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background: #ececec;
        color: red;
      }
    }
    .plus-element {
      opacity: .4;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;
      background: #dee5e8;
      cursor: cell;
      &:hover {
        background: #4b4d4f;
        color: white;
        opacity: .9;
      }
    }
  }
  .edit { padding: 5px 10px; border: 1px solid black }
  table {
    width: 100%;
    border-collapse: collapse;


    //  add sticky to table headers
    thead th { position: sticky; top: 0; z-index: 1; }

    tbody tr td {
      display: flex;
      align-items: center;
      .table-true, .table-false {
        display: table;
        margin: 0 auto;
      }
    }

    //  basic cell styling
    th, td {
      font-size: 12px;
      text-align: left;
      border: 1px solid #ccc;
      padding: 8px;
    }

    //  borders
    th:not(:first-of-type) { border-left: 0; }
    td {
      border-top: 0;
      &:not(:first-of-type) { border-left: 0; }
    }

    //  colors
    th {
      background: lighten($color, 0%);
      color: white;
      box-shadow: 0 3px 3px 0 rgba(black, 0.1);
    }
    tr:nth-of-type(even) td { background: whitesmoke; }
    tr:hover td { background: lighten(black, 60%); color:white; }


    //  header + sorting
    th {
      cursor: pointer; font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 60px;
      transition: background-color 150ms;
      padding: 16px 8px 16px 22px;

    }
    th:before {
      content: '';
      display: none;
      color: rgba(white, 0.4);
      font-family:'Material Icons', sans-serif;
      transition: transform 300ms, color 300ms;
      position: absolute;
      font-size: 18px;
      left: 4px;
      top: calc(50% - 9px);
    }
    .ascending:before  { transform: rotate(-90deg); color: white; }
    .descending:before { transform: rotate( 90deg); color: white; }

    .ascending   { background-color: lighten($color, 10%); }
    .descending  { background-color: darken($color, 10%); }
  }
  table.questBlock {
    @supports (display: contents){
      // attempt to prevent th border paint issue
      display: grid;
      grid-template-columns: repeat(2, auto);
      thead, tbody, tr { display: contents; }
      th {border-color: darken($color, 5%); }
    }
  }
  table.business {
    @supports (display: contents){
      // attempt to prevent th border paint issue
      display: grid;
      grid-template-columns: repeat(4, auto);
      thead, tbody, tr { display: contents; }
      th {border-color: darken($color, 5%); }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0
  }
}

/* ЗАГРУЗКА ИЗОБРАЖЕНИЯ */
.file{
  display: none;
}
</style>
