<template>
  <div class="cart">
    <Sceleton v-if="!getDatabase"/>
    <div v-else>
      <section class="body">
        <div class="container" >
          <div class="row">
            <div class="col-lg-9 col-md-12">

              <div class="bigbox">
                <p class="bigbox_title">{{getDatabase.name}}</p>
                <img :src="getDatabase.image" class="bigbox_imgg" alt="404">
                <p class="bigbox_info" v-html="getDatabase.text"></p>

                <div class="linear_bigbox"></div>
                <div class="dots">
                  <router-link
                    :to="{name: 'Cart', params: {id: (Number($attrs.id) - 1)}}"
                    :class="{'pointerEventsNone': Number($attrs.id) <= 1}"
                    class="prew">
                    <img src="../assets/icons/cart/prew.svg" class="prew_img" alt="404">
                    <div class="prew_title">Предыдущая публикация</div>
                    <div class="prew_titles">Пред </div>
                  </router-link>
                  <router-link
                    :to="{name: 'Cart', params: {id: (Number($attrs.id) + 1)}}"
                    :class="{'pointerEventsNone': Number($attrs.id) >= $root.database.articles.length }"
                    class="next">
                    <div class="next_title">Следующая публикация  </div>
                    <div class="next_titles">След </div>
                    <img src="../assets/icons/cart/next.svg" class="next_img" alt="404">
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="row">
                <div class="col-12">
                  <div class="other">
                    <p class="other_title">Другие</p>
                    <p class="other_tttitle"> публикации</p>
                    <a target="_blank" href="https://xn----8sbbqkh9cjj0j.xn--p1ai/" class="publick">
                      <img src="../assets/icons/cart/varaq.svg" class="other_img" alt="404">
                      <p class="other_titletwo">Шаблоны документов </p>
                      <p class="other_info">по всем юридическим вопросам</p>
                      <button type="button" class="btn other_knop ">Получить за 299 руб</button>
                    </a>
                  </div>
                </div>
                <router-link to="/month" class="publick col-12">
                  <div class="otherTwo">
                    <img src="../assets/images/back.png" class="otherTwo_img" alt="404">
                    <p class="otherTwo_title" style="font-size: 18.5px; margin-bottom: 20px">Первый месяц бесплатно</p>
                    <button type="button" class="btn otherTwo_knop ">Перейти</button>
                  </div>
                </router-link>
                <router-link to="/referrals" class="publick col-12">
                  <div class="otherThree">
                    <img src="../assets/images/referal.png" class="otherThree_img" alt="404">
                    <p class="otherThree_info">Бесплатный юрист навсегда</p>
                    <a href="#" class="otherThree_per">Перейти <img src="../assets/icons/cart/arrow.svg" class="otherThree_per_img" alt="404"></a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Form style="margin: 40px 0"/>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Form";
import Sceleton from "@/components/Sceleton";
export default {
  name: "Cart",
  components: {Sceleton, Form},
  computed: {
    getDatabase () {
      return this.$root.database.articles[Number(this.$route.params.id) -1]
    }
  }
}
</script>


<style scoped lang="scss">
@import "../assets/css/about.css";
@import "../assets/css/cart.css";
@import "../assets/css/cartMedia.css";
a {
  &:hover {
    text-decoration: none!important;
  }
}
.pointerEventsNone {
  pointer-events: none;
  opacity: 0.5;
}
.publick {
  button {
    border-color: #51D585;
    color: #000;
    background-image: -webkit-linear-gradient(45deg, #51D585 50%, transparent 50%);
    background-image: linear-gradient(45deg, #51D585 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
  }
  &:hover {
    button {
      color: #fff;
      background-position: 0;
    }
  }
}
</style>
