<template>
  <section class="login">
    <div v-if="auth" class="form">
      <h1
          class="logout"
          style=""
          @click="LOGOUT"
      >Выйти
        <svg style="width:34px;height:34px; margin-left: 15px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z" />
        </svg>
      </h1>
    </div>
    <div class="form" v-if="!auth" >
      <div class="holder">
        <input type="text" id="name" @focus.stop.prevent='isFocused(nameObj)' @blur="isBlur(nameObj)" v-model.lazy:value='nameObj.name' v-model="form.login" autocomplete="off">
        <label for="name" :class="nameObj.nameClass">Логин</label>
      </div>
      <div class="holder">
        <input type="password" id="inputText" @focus='[isFocused(pwObj),enterClass=["enter","opacity"]]' @blur="isBlur(pwObj)" v-model.lazy:value='pwObj.name' v-model="form.pass" v-on:keyup.enter="click">
        <label for="inputText" :class="pwObj.nameClass" >Пароль</label>
        <div class="earser" :style="{left:left}">
          <div class="pacman"></div>
          <div class="dotsBlocker"></div>
        </div>
      </div>
      <svg  preserveAspectRatio="none">
        <g :class="pwObj.lineClass" >
          <line x1="160" y1="150" x2="292" y2="150" ></line>
          <path  d="m 60 150 Q 110 150 160 150" fill="none">
            <animate
                attributeName="d"
                from = "M 60 150 Q 110 150 160 150"
                to =   "M 60 150 Q 110 150 160 150"
                dur="500ms"
                repeatCount="1"
                keyTimes="0; 0.4; 0.6; 0.8;0.9; 1"
                values= "M 60 150 Q 110 150 160 150;
                         M 60 150 Q 110 160 160 150;
                         M 60 150 Q 110 80 160 150;
                         M 60 150 Q 110 160 160 150;
                         M 60 150 Q 110 140 160 150;
                         M 60 150 Q 110 150 160 150;"
                begin="start"
                id="nameLineA"
            />
          </path>
        </g>
        <g :class="nameObj.lineClass">
          <line x1="160" y1="50" x2="292" y2="50" ></line>
          <path  d="M 60 50 Q 110 50 160 50" fill="none">
            <animate
                attributeName="d"
                from = "M 60 50 Q 110 10 160 50"
                to =   "M 60 50 Q 110 50 160 50"
                dur="500ms"
                repeatCount="1"
                keyTimes="0; 0.4; 0.6; 0.8;0.9; 1"
                values= "M 60 50 Q 110 50 160 50;
                         M 60 50 Q 110 60 160 50;
                         M 60 50 Q 110 50 160 50;
                         M 60 50 Q 110 10 160 50;
                         M 60 50 Q 110 60 160 50;
                         M 60 50 Q 110 50 160 50;"
                begin="start"
                id="nameLineB"
            />
          </path>
        </g>
      </svg>
      <div :class="enterClass">press enter</div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "Login",
  data() {
    return {
      nameObj:{name:"",nameClass:"",lineClass:"lines",lineObj:"nameLineB"},
      pwObj:{name:"",nameClass:"",lineClass:"lines",lineObj:"nameLineA"},
      left:"-220px",
      enterClass:["enter"],
      form: {
        login: null,
        pass: null,
        error: null
      }
    }
  },
  computed: {
    auth () {
      return localStorage.user
    }
  },
  methods:{
    ...mapActions([
      'LOGIN',
      'LOGOUT'
    ]),
    click: async function(){
      this.left=20+"px";
      let vm=this;
      setTimeout(function(){
        vm.left=-220+"px";
        vm.pwObj.name="";
        vm.pwObj.nameClass=[];
        vm.pwObj.lineClass="lines";
        vm.nameObj.name="";
        vm.nameObj.nameClass=[];
        vm.enterClass=["enter"];
      },5000)
      const formData = {
        login: this.form.login,
        pass: this.form.pass
      }
      await this.LOGIN(formData)
    },
    isFocused:function(obj,groupClass){
      obj.nameClass=["focused","filled"];
      obj.lineClass=["lines","lineFilled"];
      let lineObj = document.getElementById(obj.lineObj);
      lineObj.beginElement();
    },
    isBlur:function(obj,groupClass){
      if (obj.name===""){
        obj.nameClass=[];
        obj.lineClass="lines";
      }else{
        obj.nameClass=["filled"];
      }
      obj.lineClass="lines";
      this.enterClass=["enter"];
    }
  }
}
</script>

<style lang="scss" scoped>
.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
  margin-top: calc(50% - 90px);
  transform: translate(-20px, 0);
  &:hover {
    color: white;
  }
  svg {
    position: absolute;
    top: calc(50% - 17px);
    left: 280px;
  }
}

//main colors and vars
$yellow: #F0D022;
$gry-bg: #111;
$light-gry: #262626;
$light-gry-bg: #121212;

@mixin tranision {
  transition: (all 400ms cubic-bezier(0, 0.4, 0.6, 1) 150ms);
  -webkit-transition: (all 400ms cubic-bezier(0, 0.4, 0.8, 1) 150ms);
}

.login{margin: 0px;padding: 0px;background-color: $gry-bg;font-family: 'Roboto';}

.login{
  width: 100vw;height: 100vh;
}

svg{
  position: relative;
  z-index: 10;
  pointer-events: none;
  width:300px; height:400px;
  left:24px; top:-160px;
}

label{
  @include tranision;
  transform:(translateY(0px));
  display: block;
  width: 180px;height: 40px;
  line-height: 40px;
  cursor: text;
  font-size: 20px;
  color: $light-gry;
  z-index: 1;
  &.filled { transform:(translateY(-20px));color:$light-gry-bg;}
  &.focused { color: $yellow;
    transform:(translateY(-30px));
    z-index: 1;
  }
}

input{
  @include tranision;
  width: 180px;height:40px;
  font-size:20px;
  background-color: $light-gry-bg;
  text-align:left;
  border:0px solid $light-gry-bg;
  position: relative;
  z-index: 0;
  color:$light-gry;
  transform:(translateX(0px)translateY(42px));
}

input:focus{
  outline: 0px;
  color:$yellow;
}

.lines{
  @include tranision;
  stroke-width:2;
  stroke:$light-gry;
}

.lineGry{ stroke:$light-gry;}

.lineFilled{ stroke:$yellow;}

.holder{
  width: 200px;height: 80px;
  margin: 20px auto;
  background-color: $light-gry-bg;
  overflow: hidden;
  z-index: 0;
}

.form{
  width: 400px;height: 300px;
  background-color: $light-gry-bg;
  border:1px solid #0b0b0b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

//pacman stuff
.earser{
  position: relative;
  float: left;
  width: 180px; height: 30px;
  background: $light-gry-bg;
  transition:4s all;
  left:-220px;top:-34px;
  z-index: 1;
}
.enter{
  @include tranision;
  width: 100px; height: 48px;
  color:$light-gry;
  border: 2px solid $light-gry;
  border-radius: 10px;
  line-height: 48px;
  text-align: center;
  position: relative;
  top:-148%; left:110%;
  opacity:0;
  z-index: 200;
  @media (max-width: 700px) {
    position: absolute;
    top: 120% !important;
    left: calc(50% - 50px);
  }
}
.opacity{
  top:-164%;
  opacity:1;
}
.pacman{
  position: relative;
  left:170px; top:-10px;
  width: 50px;height: 25px;
  background-color: $yellow;
  border-radius:50px 50px 0px 0px;
  animation: pacmanMouthB 0.2s linear infinite;
  transform-origin:center bottom;
  z-index: 2;
}

.pacman:after{
  content:"";
  position: absolute;
  top:25px;
  width: 50px;height: 25px;
  background-color: $yellow;
  border-radius:0px 0px 50px 50px;
  animation: pacmanMouthA  0.2s linear infinite;
  transform-origin:center top;
  z-index: 2;
}

.dotsBlocker{
  width: 250px;height: 30px;
  background-color: $light-gry-bg;
  position: relative;
  left:-50px; top:-25px;
  transition:3s all;
  z-index: 1;
}

@keyframes pacmanMouthA
{0% { transform: rotate(60deg)scale(1);}
  50% { transform: rotate(0deg)scale(1);}
  100% {transform: rotate(60deg)scale(1);}}

@keyframes pacmanMouthB
{0% { transform: rotate(-30deg)scale(0.5);}
  50% { transform: rotate(0deg)scale(0.5);}
  100% {transform: rotate(-30deg)scale(0.5);}}

</style>