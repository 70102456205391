<template>
  <div class="hello" >
    <div class="wrapper">
      <div :class="{
        'wrap':$route.path === '/',
        'wrap-page-1':$route.path === '/1',
        'wrap-page-2':$route.path === '/2',
        'wrap-page-3':$route.path === '/3',
        'wrap-page-2':$route.path === '/franchise'
      }">
        <div data-v-7e5084da="" class="container">
          <div data-v-7e5084da="" class="backone_body">
            <h1 v-if="$route.path === '/'" class="backone_title">ЮРИДИЧЕСКИЙ АУТСОРСИНГ - <br/> КАЧЕСТВЕННО НЕ ЗНАЧИТ ДОРОГО</h1>
            <h1 v-else-if="$route.path === '/1'" class="backone_title">Юридические услуги</h1>
            <h1 v-else-if="$route.path === '/2'" class="backone_title">Юрист для бизнеса</h1>
            <h1 v-else-if="$route.path === '/franchise'" class="backone_title">НАЧНИ ЗАРАБАТЫВАТЬ НА </h1>
<!--            <h1 v-else class="backone_title">Юридический отдел</h1>-->
            <span v-if="$route.path === '/1'" class="backone_title">для бизнеса</span>
            <span v-if="$route.path === '/2'" class="backone_title">по подписке</span>
            <span v-if="$route.path === '/3'" class="backone_title">для вашего бизнеса</span>
            <span v-if="$route.path === '/franchise'" class="backone_title">ЮРИДИЧЕСКОМ ОБСЛУЖИВАНИИ БИЗНЕСА</span>
            <p data-v-7e5084da="" class="backone_info">
              {{
                $route.path==='/' ? 'Юрист по подписке за 10 тысяч руб./мес.'
                    :$route.path === '/1'? 'из ДНР, ЛНР, Херсонской и Запорожской областей '
                        :$route.path === '/2'? 'Попробуйте бесплатно'
                            :$route.path === '/3'? 'Всего за 10 тыс рублей в месяц'
                                :$route.path === '/franchise'? 'Открою тебе бизнес на твоих условиях!'
                                    : ''
              }}
            </p>
            <p class="backone_info" v-if="$route.path === '/2'">Далее - всего 10000 руб/мес</p>
            <div class="button-box" v-if="1 === 2">
              <div data-v-7e5084da="" class="backone_body_box">
                <h1 v-if="$route.path !== '/franchise'" class="backone_body_box_title">Первый месяц бесплатно</h1>
                <h1 v-else class="backone_body_box_title">Я успею</h1>
              </div>
            </div>
            <a class="btn head-button" @click="modal = true">{{$route.path === '/franchise' ? 'ЖДУ ПОДРОБНОСТИ' : $route.path !== '/franchise' ? 'Оставить заявку' : ''}}</a>

          </div>
        </div>
      </div>
      <div data-v-7e5084da="" class="backone_footer wrap">
        <div class="container">
          <div data-v-7e5084da="" class="row">
            <div data-v-7e5084da="" class="col-md-4 col-sm-12">
              <div data-v-7e5084da="" class="sotrudnik">
                <img data-v-7e5084da="" src="../assets/icons/sotrudnik.svg" alt="404" class="sotrudnik_img">
                <p data-v-7e5084da="" class="sotrudnik_title">
                  {{sotrudnikText}}
                </p>
              </div>
            </div>
            <div data-v-7e5084da="" class="col-md-4 col-sm-12">
              <div data-v-7e5084da="" class="money">
                <img data-v-7e5084da="" src="../assets/icons/money.svg" alt="404" class="money_img">
                <div v-if="$route.path === '/'">
                  <p data-v-7e5084da="" class="money_title">Удобнее фриланса</p>
                  <p data-v-7e5084da="" class="money_info">ведь мы погружены в Ваш бизнес и всегда на связи</p>
                </div>
                <div>
                  <p data-v-7e5084da="" class="money_title">{{moneyText}}</p>
                </div>
              </div>
            </div>
            <div data-v-7e5084da="" class="col-md-4 col-sm-12"><div data-v-7e5084da="" class="tuqmoq"><img data-v-7e5084da="" src="../assets/icons/tuqmoq.svg" alt="404" class="tuqmoq_img"><div data-v-7e5084da=""><p data-v-7e5084da="" class="tuqmoq_title">Для работы не нужен офис</p></div></div></div>
          </div>
        </div>
      </div>
      <section class="body_five">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="bissTable">
                <p class="bissTable_title">ДАВАЙ СРАЗУ ЗАРАБАТЫВАТЬ ПО-КРУПНОМУ. </p>
                <p class="bissTable_info">Миллион рублей в месяц чистыми для начала на этом бизнесе тебя устроит?</p>
                <div class="table-container">
                  <table class="table table-bordered" v-if="getDatabase">
                    <thead>
                    <tr>
                      <th class="thead_title" :class="{'active':col.prop === '2'}" v-for="(col, i) in business"
                          :key="i"
                      >{{ col.label }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in getDatabase.business" :key="i">
                      <td
                          :class="{'tbody_one':col.prop === '1', 'tbody_title':col.prop !== '1'}"
                          v-for="(col, index) in business"
                          :key="index"
                      >{{row[col.prop]}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="body">
        <div class="container">
          <div class="vxod" id="scroll-package">
            <h1 class="vxod_title">ТЫ ПОЛУЧИШЬ </h1>
            <p class="vxod_info">Преимущества от персонального наставничества</p>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">Готовый бизнес с понятной моделью</h1>

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">Выйдешь на миллионный доход</h1>

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">Пошаговый план для дальнейшего развития</h1>

                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">Топовую персональную поддержку</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 40px" class="kak">
            <h1 class="kak_title">

              {{
                $route.path==='/' ? 'КАК ЭТО РАБОТАЕТ'
                    :$route.path === '/1'? 'КАК ЭТО РАБОТАЕТ'
                        :$route.path === '/2'? 'КАК ЭТО РАБОТАЕТ'
                            :$route.path === '/3'? 'КАК ЭТО РАБОТАЕТ'
                                :$route.path === '/franchise'? 'КАК РАБОТАЕТ БИЗНЕС'
                                    : ''
              }}

            </h1>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="user">
                  <img src="../assets/icons/iconsuser.svg" class="user_img" alt="404">
                  <p v-if="$route.path === '/'" class="user_title">Закрепление персонального куратора</p>
                  <p v-else class="user_title">
                    {{
                    $route.path==='/' ? 'У вас есть персональный куратор'
                    :$route.path === '/1'? 'У вас есть персональный куратор'
                    :$route.path === '/2'? 'У вас есть персональный куратор'
                    :$route.path === '/3'? 'У вас есть персональный куратор'
                    :$route.path === '/franchise'? 'Находите клиентов. Это быстро и ничего не стоит'
                    : ''
                    }}
                  </p>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="chat">
                  <img src="../assets/icons/chaticins.svg" class="chat_img " alt="404 ">
                  <p v-if="$route.path === '/'" class="chat_title ">Возникает вопрос - просто напишите его</p>
                  <p v-else class="chat_title ">

                    {{
                      $route.path==='/' ? 'Возникает вопрос - просто напишите его и куратор подберет специализированного юриста'
                          :$route.path === '/1'? 'Возникает вопрос - просто напишите его и куратор подберет специализированного юриста'
                              :$route.path === '/2'? 'Возникает вопрос - просто напишите его и куратор подберет специализированного юриста'
                                  :$route.path === '/3'? 'Возникает вопрос - просто напишите его и куратор подберет специализированного юриста'
                                      :$route.path === '/franchise'? 'У Вас на обслуживании 100-150 компаний за 15000 рублей/месяц'
                                          : ''
                    }}

                  </p>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 ">
                <div class="pazzle ">
                  <img src="../assets/icons/puzzle.svg" class="pazzle_img " alt="404 ">
                  <p v-if="$route.path !== '/1'" class="pazzle_title ">
                    {{
                      $route.path==='/' ? 'Решим все вопросы, а оплата только в конце месяца'
                          :$route.path === '/2'? 'Решим все вопросы, а оплата только в конце месяца'
                              :$route.path === '/3'? 'Решим все вопросы, а оплата только в конце месяца'
                                  :$route.path === '/franchise'? 'Чистый доход – от 1 000 000 рублей в месяц'
                                      : ''
                    }}
                  </p>
                  <p v-else class="pazzle_title ">Точно знаем, что нужно и чем помочь бизнесу, входящему в РФ</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Form style="margin: 60px 0 100px 0" :type="1" id="form1"/>
      <section class="body_seven" v-if="$route.path !== '/franchise'">
        <div class="container">
          <div class="col-12">
            <div class="klient">
              <p class="klient_title">СРЕДИ НАШИХ КЛИЕНТОВ</p>
              <p v-if="$route.path === '/1'" class="klient_title" style="font-size: 20px;opacity: 0.8">УЖЕ БОЛЕЕ 30 КОМПАНИЙ ИЗ ЛДНР, ХЕРСОНА, МЕЛИТОПОЛЯ, БЕРДЯНСКА</p>
              <div id="demotwo" class="carousel slide" data-ride="carousel">
                <ul class="carousel-indicators">
                  <li data-target="#demotwo" data-slide-to="0" class="active"></li>
                  <li data-target="#demotwo" data-slide-to="1"></li>
                  <li data-target="#demotwo" data-slide-to="2"></li>
                </ul>
                <div class="carousel-inner vhs">
                  <div class="carousel-item active">
                    <div class="reklama">
                      <VueSlickCarousel v-bind="settings_clients">
                        <img src="../assets/icons/1.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/2.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/3.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/4.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/5.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/6.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/7.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/8.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/9.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/10.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/11.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/12.svg" class="reklama_two_img" alt="404">
                        <template #prevArrow="arrowOption">
                          <div class="custom-arrows-class custom-arrows-class-prev">
                            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                          </div>
                        </template>
                        <template #nextArrow="arrowOption">
                          <div class="custom-arrows-class">
                            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                          </div>
                        </template>
                      </VueSlickCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Form :type="2" style="margin-bottom: 50px"/>

    </div>
    <section style="margin-bottom: 70px" class="body_fourr">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <VueSlickCarousel :options="slickOptions" v-bind="slickOptions">
              <!-- Слайд 1 -->
              <div class="carousel-item">
                <div class="slide-content">
                  <div class="slide-content-inner">
                    <div class="slide-text">
                      <div class="slide-title">
                        <img src="../assets/icons/suroq.svg" class="slide-image" alt="Изображение слайда 1">
                        <p>Можно ли вести бизнес удаленно?</p>
                      </div>
                      <p class="slide-subtitle">Да. Мы предлагаем несколько вариантов – удаленной работы и работы с офисом, где, кстати, также большинство процессов строится удаленно</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Слайд 2 -->
              <div class="carousel-item">
                <div class="slide-content">
                  <div class="slide-content-inner">
                    <div class="slide-text">
                      <div class="slide-title">
                        <img src="../assets/icons/suroq.svg" class="slide-image" alt="Изображение слайда 1">
                        <p>Мне не обязательно быть юристом чтобы заниматься этим бизнесом? </p>
                      </div>
                      <p class="slide-subtitle">Все верно. Мы даем инструменты каким образом организовать эту работу и получать прибыль не исполняя самостоятельно</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Слайд 3 -->
              <div class="carousel-item">
                <div class="slide-content">
                  <div class="slide-content-inner">
                    <div class="slide-text">
                      <div class="slide-title">
                        <img src="../assets/icons/suroq.svg" class="slide-image" alt="Изображение слайда 1">
                        <p>Как строится работа по франшизе?</p>
                      </div>
                      <p class="slide-subtitle">Это больше наставничество, а не франшиза. Мы берем Вас за руку и вместе делаем каждый шаг организовывая ваш стабильный быстрорастущий бизнес</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Слайд 4 -->
              <div class="carousel-item">
                <div class="slide-content">
                  <div class="slide-content-inner">
                    <div class="slide-text">
                      <div class="slide-title">
                        <img src="../assets/icons/suroq.svg" class="slide-image" alt="Изображение слайда 1">
                        <p>100 000 рублей чистыми это сколько клиентов?</p>
                      </div>
                      <p class="slide-subtitle">Это всего 10 клиентов в месяц. Их Вы наберете за первые несколько дней нашей работы и уже получите свою первую чистую прибыль!</p>
                    </div>
                  </div>
                </div>
              </div>


              <!-- Добавьте дополнительные слайды по аналогии -->
              <template #prevArrow="arrowOption">
                <div class="custom-arrows-class custom-arrows-class-prev">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <template #nextArrow="arrowOption">
                <div class="custom-arrows-class">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </section>

    <Modal v-if="modal" @close="modal = false" :is-close="false" :is-policy="false">
      <template v-slot:body>
        <Form :type="3"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

// optional style for arrows & dots
import Modal from "./notifications/Modal.vue";
import Form from "../components/Form.vue";

export default {
  name: 'Franchise',
  components: { Form, VueSlickCarousel, Modal },

  mounted() {

    (function(m, e, t, r, i, k, a) {
      m[i] = m[i] || function() {
        (m[i].a = m[i].a || []).push(arguments);
      };
      m[i].l = 1 * new Date();
      for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) {
          return;
        }
      }
      k = e.createElement(t);
      a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    /* global ym */
    ym(95215562, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });
  },

  data() {
    return {
      modal: false,
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true, // Добавляем индикаторы (точки) внизу
        prevArrow: '<button type="button" class="slick-prev">Previous</button>', // Кастомная кнопка "Previous"
        nextArrow: '<button type="button" class="slick-next">Next</button>', // Кастомная кнопка "Next"
        // и другие параметры по вашему усмотрению
      },

    };
  },
  computed: {
    getDatabase () {
      return this.$root.database.Franchise
    },
    isMobile () {
      return window.innerWidth > 480
    },

    moneyText() {
      if(this.$route.path === '/1') {
        return 'Поможем интегрироваться<br>в Российский бизнес'
      } else if(this.$route.path === '/2') {
        return 'Поможем интегрироваться<br>в Российский бизнес'
      } else if(this.$route.path === '/3') {
        return 'Поможем интегрироваться<br>в Российский бизнес'
      } else if(this.$route.path === '/franchise') {
        return 'Востребован во всех регионах'
      }
      return ''
    },
    sotrudnikText() {
      if(this.$route.path === '/1') {
        return 'Дешевле собственного<br>сотрудника'
      } else if(this.$route.path === '/2') {
        return 'Дешевле собственного<br>сотрудника'
      } else if(this.$route.path === '/3') {
        return 'Дешевле собственного<br>сотрудника'
      } else if(this.$route.path === '/franchise') {
        return 'Бизнес без конкуренции'
      }
      return ''
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/css/home.css";
@import "../assets/css/homeMedia.css";

.view {
  margin: 0;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ccc; /* Цвет стрелок */
  color: #fff; /* Цвет текста стрелок */
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
  /* Установите высоту слайда по вашему желанию */
}

.slide-content {
  display: flex;
  flex-direction: row; /* Расположение изображения и текста в ряд */
  align-items: center;
  text-align: left; /* Выравнивание текста слева */
  justify-content: center; /* Выравнивание контента по центру */
}

.slide-content-inner {
  display: flex;
  flex-direction: column;
}

.slide-image {
  max-width: 30%; /* Ширина изображения */
  margin-right: 20px; /* Пространство между изображением и текстом */
}

.slide-title {
  display: flex;
  font-size: 24px; /* Размер шрифта заголовка */
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.slide-subtitle {
  font-size: 18px; /* Размер шрифта подзаголовка */
}
.slide-text {
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-height: 1920px) {
  .backone_body {
    height: calc(100vh - 88px)!important;
  }
  .head-button {
    bottom: 400px!important;
  }
}

@media (max-height: 686px) {
  .backone_body {
    height: calc(100vh - 88px)!important;
  }
  .head-button {
    bottom: 100px!important;
  }
}
@media (max-height: 815px) {
  .backone_body {
    height: calc(100vh - 88px)!important;
  }
  .head-button {
    margin-top: 250px;
    bottom: 150px!important;
  }
}


.body {
  @media (max-width: 750px) {
    margin-bottom: 20px;
  }
}
.reklama{
  img {
    max-width: 150px;
  }
}
.body_four,
.reklama{
  @media (max-width: 1220px) {
    padding: 0 20px;
  }
}
.body_seven .container {
  @media (max-width: 1220px) {
    padding: 0;
  }
}
.body_seven .container .col-12 {
  @media (max-width: 1220px) {
    padding: 10px;
  }
}
.reklama_one {
  @media (max-width: 430px) {
    img {
      max-width: 100px;
      pointer-events: none;
    }
  }
}
.table-container {
  width: 100%;
  max-width: 100%;
  @media (max-width: 640px) {
    overflow: auto;
  }
}

.custom-arrows-class {
  background: black;
  background: url("../assets/icons/chevron-right.png") center no-repeat;
  height: 100px;
  opacity: 0.5;
  &:before {
    display: none;
  }
}
.custom-arrows-class-prev {
  background: url("../assets/icons/chevron-left.png") center no-repeat;
}

.backone_body {
  position: relative;
  height: calc(100vh - 350px);
}
.backone_footer {
  background-color: #989898;
  padding: 40px 0;
  &.wrap {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
  }
}
.thead_title {
  text-align: center;
  color: #4CA871;
}
.thead_title:hover {
  color: white;
}
.wrap {
  width: 100%;
  background: url("../assets/images/backone1.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.wrap-page-1 {
  width: 100%;
  background: url("../assets/images/page1.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.wrap-page-2 {
  width: 100%;
  background: url("../assets/images/page2.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.wrap-page-3 {
  width: 100%;
  background: url("../assets/images/page3.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.vopros {
  @media (max-width: 500px) {
    padding: 10px;
  }
  .vopros_box {
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 20px;
      }
    }
    .vopros_box_title {
      @media (max-width: 500px) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
.thead_title.active{
  text-align: center;
  background: #51D585;
  box-shadow: 0px 0px 24px rgb(56 163 54 / 50%), inset 0px 1px 0px rgb(0 0 0 / 15%), inset 1px 0px 0px rgb(0 0 0 / 15%);
  color: white;
  cursor: pointer;
}
.btn {
  padding: 15px 24px;
  background: #51D585;
  color: white!important;
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 171px;
  max-width: 300px;
  margin-top: 50px;
  @media (min-width: 700px) {
    font-size: 14px;
  }
}
.head-button {
  position: absolute;
  bottom: 100px;
  font-size: 32px;
  max-width: 400px;
  @media (max-width: 500px) {
    font-size: 26px;
    width: 100%;
    margin: 20px 0;
    bottom: 0px;
  }
}
.button-box {
  display: flex;
  align-items: flex-end;
}
.button-box .btn {
  max-height: 40px;
  margin-left: 40px;
  margin-bottom: 13px;
}
@media (max-width: 700px) {
  .button-box {
    flex-direction: column;
  }
  .btn {
    margin-top: 20px;
  }
}

</style>
