<template>
  <section style="margin-bottom: 70px" class="body_four">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div id="demo" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" v-if="getDatabase">
              <VueSlickCarousel v-bind="settings">
                <div
                    v-for="(quest, i) in getDatabase.questBlock"
                    :key="i"
                    class="carousel-item">
                  <div class="vopros">
                    <div class="vopros_box">
                      <img src="../assets/icons/suroq.svg" class="vopros_box_img" alt="404">
                      <p class="vopros_box_title" v-html="quest.title"></p>
                    </div>
                    <h1 class="vopros_title" v-html="quest.subtitle"></h1>
                  </div>
                </div>
                <template #prevArrow="arrowOption">
                  <div class="custom-arrows-class custom-arrows-class-prev">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
                <template #nextArrow="arrowOption">
                  <div class="custom-arrows-class">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: 'CustomSlider',
  components: { VueSlickCarousel },
  props: {
    getDatabase: Object, // Передавайте объект getDatabase в качестве пропса
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.custom-arrows-class {
  background: black;
  background: url("../assets/icons/chevron-right.png") center no-repeat;
  height: 100px;
  opacity: 0.5;
}

.custom-arrows-class-prev {
  background: url("../assets/icons/chevron-left.png") center no-repeat;
}
</style>
