<template>
  <div class="about">
    <Sceleton v-if="!getDatabase"/>
    <div v-else>
      <div class="wrap">
        <div data-v-7e5084da="" class="container">
          <div data-v-7e5084da="" class="header_body">
            <h3 data-v-7e5084da="" style="color: white" class="header_body_title">РАБОТАЕМ ВО ВСЕХ ГОРОДАХ РФ</h3>
            <div data-v-7e5084da="" class="row">
              <div data-v-7e5084da="" class="col-lg-8 col-md-12">
                <div data-v-7e5084da="" class="header_body_boxOne">
                  <p data-v-7e5084da="" style="color: white" class="header_body_boxOne_title">
                    Оказываем дистанционную юридическую помощь – удобный и экономный способ получения квалифицированной юридической поддержки без личного присутствия специалиста. Являемся НКО, а значит в отличие от ООО и ИП работаем под контролем Министерства Юстиции РФ, который обеспечивает дополнительную гарантию безопасности для вас. 62 опытных юриста, 14 специализаций и направлений - это гарантия качества нашей работы.
                  </p>
                  <div  data-v-7e5084da="" class="pdf_box">
                    <div style="cursor: pointer" @click="modal.minust = true" data-v-7e5084da="" class="pdf_boxOne">
                      <img data-v-7e5084da="" src="../assets/icons/pdf.svg" alt="404" class="pdf_boxOne_img">
                      <p data-v-7e5084da="" class="pdf_boxOne_title" style="color: white">Свидетельство Министерства Юстиции РФ</p>
                    </div>
                    <div style="cursor: pointer" @click="modal.cart = true" data-v-7e5084da="" class="pdf_boxTwo"><img data-v-7e5084da="" src="../assets/icons/pdf.svg" alt="404" class="pdf_boxTwo_img">
                      <p data-v-7e5084da="" class="pdf_boxTwo_title" style="color: white">Карточка организации</p>
                    </div>
                    <div style="cursor: pointer" @click="modal.gosReg = true" data-v-7e5084da="" class="pdf_boxThree">
                      <img data-v-7e5084da="" src="../assets/icons/pdf.svg" alt="404" class="pdf_boxThree_img">
                      <p data-v-7e5084da="" class="pdf_boxThree_title" style="color: white">Свидетельство о госрегистрации</p>
                    </div>
                  </div>
                </div>
              </div><div data-v-7e5084da="" class="col-lg-4 col-md-12"><div data-v-7e5084da="" class="play"><img data-v-7e5084da="" src="../assets/images/backone22.jpg" alt="404" class="play_img">
            </div></div></div></div></div>
      </div>
      <section class="body">
        <div class="container">
          <div class="yurist">
            <h3 class="yurist_title" id="scroll-lawyer">НАШИ ЮРИСТЫ</h3>
            <div class="carousel">
              <VueSlickCarousel v-bind="settings">
                <div class="item" v-for="(lawyer, index) in getDatabase[0].data" :key="'about-lawyer'+index">
                  <img :src="lawyer.image" class="item_img" alt="404">
                  <p class="item_title" v-html="lawyer.name"></p>
                  <p class="item_titlee" v-html="lawyer.info"></p>
                </div>
                <template #prevArrow="arrowOption">
                  <div class="custom-arrows-class custom-arrows-class-prev">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
                <template #nextArrow="arrowOption">
                  <div class="custom-arrows-class">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
              </VueSlickCarousel>
            </div>
          </div>
          <div class="dost">
            <p class="dost_title">НАШИ ДОСТИЖЕНИЯ</p>
            <VueSlickCarousel v-bind="settings">
              <div class="itemTwo" v-for="(achievements, index) in getDatabase[1].data" :key="'about-achievements'+index">
                <img :src="achievements.image" class="itemTwo_img" alt="404">
              </div>
              <template #prevArrow="arrowOption">
                <div class="custom-arrows-class custom-arrows-class-prev">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <template #nextArrow="arrowOption">
                <div class="custom-arrows-class">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
            </VueSlickCarousel>
          </div>

          <div class="budi">
            <p class="budni_title">НАШИ БУДНИ</p>
            <VueSlickCarousel v-bind="settings">
              <div class="itemThree" v-for="(weekdays, index) in getDatabase[2].data" :key="'about-weekdays'+index">
                <img :src="weekdays.image" class="itemThree_img" alt="404">
              </div>
              <template #prevArrow="arrowOption">
                <div class="custom-arrows-class custom-arrows-class-prev">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <template #nextArrow="arrowOption">
                <div class="custom-arrows-class">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
            </VueSlickCarousel>
          </div>
          <div class="nashyur">
            <p class="nashyur_title">ПУБЛИКАЦИИ НАШИХ ЮРИСТОВ</p>
            <VueSlickCarousel v-bind="settings">
              <div class="item_four" v-for="(article, index) in getDatabaseArticles" :key="'articles-'+index">
                <img :src="article.image" class="item_four_img" alt="404">
                <p class="item_four_title" v-html="article.name"></p>
                <router-link :to="{name: 'Cart', params: {id: index + 1}}" class="item_four_pere">
                  <div class="item_four_pere_title">Перейти</div>
                  <img src="../assets/icons/about/pere.svg" class="item_four_pere_imgs" alt="404">
                </router-link>
              </div>
              <template #prevArrow="arrowOption">
                <div class="custom-arrows-class custom-arrows-class-prev">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <template #nextArrow="arrowOption">
                <div class="custom-arrows-class">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
      </section>
      <Form/>
      <Modal
        v-show="modal.minust"
        @close="modal.minust = false"
        :isClose="true"
        :is-policy="true"
      >
        <template v-slot:body>
          <img src="../assets/docs/min-ust.jpg" alt="">
        </template>
      </Modal>
      <Modal
        v-show="modal.gosReg"
        @close="modal.gosReg = false"
        :isClose="true"
        :is-policy="true"
      >
        <template v-slot:body>
          <img src="../assets/docs/gos-reg.jpg" alt="">
        </template>
      </Modal>
      <Modal
        v-show="modal.cart"
        @close="modal.cart = false"
        :isClose="true"
        :is-policy="true"
      >
        <template v-slot:body>
          <img src="../assets/docs/img.png" alt="">
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Form from "@/components/Form";
import Modal from "@/components/notifications/Modal";
import Sceleton from "@/components/Sceleton";
export default {
  name: "About",
  components: {Sceleton, Form, VueSlickCarousel, Modal },
  data () {
    return {
      settings: {
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 5,
        "slidesToScroll": 2,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
      modal: {
        minust: false,
        gosReg: false,
        cart: false
      }
    }
  },
  computed: {
    getDatabase () {
      return this.$root.database.about
    },
    getDatabaseArticles () {
      return this.$root.database.articles
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/about.css";
@import "../assets/css/aboutMedia.css";
.item, .itemTwo, .itemThree, .item_four {
  img {
    max-width: 100%;
    padding: 0 5px;
    pointer-events: none;
  }
}
.wrap {
  width: 100%;
  background: url("../assets/images/backone2.png");
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  color: white!important;
}
.itemThree {
  width: 200px !important;
}
.custom-arrows-class {
  background: black;
  background: url("../assets/icons/chevron-right.png") center no-repeat;
  height: 100px;
  opacity: 0.5;
  &:before {
    display: none;
  }
}
.custom-arrows-class-prev {
  background: url("../assets/icons/chevron-left.png") center no-repeat;
}
</style>
