<template>
    <div class="v-notification">
      <transition-group
        name="v-transition-animate"
        class="messages_list"
      >
        <div
          class="v-notification__content"
          v-for="message in messages"
          :key="message.id"
          :class="message.icon"
        >
          <div class="content__text">
            <span>{{ message.name }}</span>
            <i v-if="1 === 2" class="material-icons">{{message.icon}}</i>
          </div>
          <div class="content__buttons">
            <button v-if="rightButton.length">{{rightButton}}</button>
            <button v-if="leftButton.length">{{leftButton}}</button>
          </div>
        </div>
      </transition-group>
    </div>
</template>

<script>
export default {
  name: 'v-notification',
  props: {
    messages: {
      type: Array,
      default: () => {
        return {}
      }
    },
    rightButton: {
      type: String,
      default: ''
    },
    leftButton: {
      type: String,
      default: ''
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data () {
    return {}
  },
  methods: {
    hideNotification () {
      const vm = this
      if (this.messages.length) {
        setTimeout(function () {
          vm.messages.splice(vm.messages.length - 1, 1)
        }, vm.timeout)
      }
    }
  },
  watch: {
    messages () {
      this.hideNotification()
    }
  },
  mounted () {
    this.hideNotification()
  }
}
</script>

<style lang="css">
  .v-notification{
    position: fixed;
    top: 80px;
    right: 16px;
    z-index: 11;
    display: flex;
    flex-direction: column-reverse;
  }
  .messages_list {
    display: flex;
    flex-direction: column;
  }
  .v-notification__content {
    padding: 16px;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 16px;
  }
  .v-notification__content.error{
    background: red;
  }
  .v-notification__content.warning {
    background: orange;
  }
  .v-notification__content.check_circle {
    background: green;
  }
  .v-transition-animate-enter {
    transform: translateX(120px);
    opacity: 0;
  }
  .v-transition-animate-enter-active {
    transition: all .6s ease;
  }
  .v-transition-animate-enter-to {
    opacity: 1;
  }
  .v-transition-animate-leave {
    height: 50px;
    opacity: 1;
  }
  .v-transition-animate-leave-active {
    transition: transform .6s ease, opacity .6s, height .6s .2s;
  }
  .v-transition-animate-leave-to {
    height: 0;
    transform: translateX(120px);
    opacity: 0;
  }
  .v-transition-animate-move {
    transition: transform .6s;
  }
</style>
