<template>
  <div class="hello" >
    <div class="wrapper">
      <div :class="{
        'wrap':$route.path === '/',
        'wrap-page-1':$route.path === '/1',
        'wrap-page-2':$route.path === '/2',
        'wrap-page-3':$route.path === '/3'
      }">
        <div data-v-7e5084da="" class="container">
          <div data-v-7e5084da="" class="backone_body">
            <h1 v-if="$route.path === '/'" class="backone_title">ЮРИДИЧЕСКИЙ АУТСОРСИНГ - <br/> КАЧЕСТВЕННО НЕ ЗНАЧИТ ДОРОГО</h1>
            <h1 v-else-if="$route.path === '/1'" class="backone_title">Юридические услуги</h1>
            <h1 v-else-if="$route.path === '/2'" class="backone_title">Юрист для бизнеса</h1>
            <h1 v-else class="backone_title">Юридический отдел</h1>
            <span v-if="$route.path === '/1'" class="backone_title">для бизнеса</span>
            <span v-if="$route.path === '/2'" class="backone_title">по подписке</span>
            <span v-if="$route.path === '/3'" class="backone_title">для вашего бизнеса</span>
            <p data-v-7e5084da="" class="backone_info">
              {{
                $route.path==='/' ? 'Юрист по подписке за 10 тысяч руб./мес.'
                    :$route.path === '/1'? 'из ДНР, ЛНР, Херсонской и Запорожской областей '
                        :$route.path === '/2'? 'Попробуйте бесплатно'
                            :$route.path === '/3'? 'Всего за 10 тыс рублей в месяц'
                                : ''
              }}
            </p>
            <p class="backone_info" v-if="$route.path === '/2'">Далее - всего 10000 руб/мес</p>
            <div class="button-box" v-if="1 === 2">
              <div data-v-7e5084da="" class="backone_body_box">
                <h1 data-v-7e5084da="" class="backone_body_box_title">Первый месяц бесплатно</h1>
              </div>
            </div>
            <a @click="modal = true" class="btn head-button">Оставить заявку</a>
          </div>
        </div>
      </div>
      <div data-v-7e5084da="" class="backone_footer wrap">
        <div class="container">
          <div data-v-7e5084da="" class="row">
            <div data-v-7e5084da="" class="col-md-4 col-sm-12">
              <div data-v-7e5084da="" class="sotrudnik">
                <img data-v-7e5084da="" src="../assets/icons/sotrudnik.svg" alt="404" class="sotrudnik_img">
                <p data-v-7e5084da="" class="sotrudnik_title">
                  {{
                    $route.path==='/' ? 'Дешевле собственного сотрудника'
                      :$route.path === '/1'? 'Дешевле, чем в других юридических компаниях'
                        :$route.path === '/2'? 'Дешевле собственного сотрудника'
                          :$route.path === '/3'? 'Дешевле собственного сотрудника'
                            : ''
                  }}
                </p>
              </div>
            </div>
            <div data-v-7e5084da="" class="col-md-4 col-sm-12">
              <div data-v-7e5084da="" class="money">
                <img data-v-7e5084da="" src="../assets/icons/money.svg" alt="404" class="money_img">
                <div v-if="$route.path === '/'">
                  <p data-v-7e5084da="" class="money_title">Удобнее фриланса</p>
                  <p data-v-7e5084da="" class="money_info">ведь мы погружены в Ваш бизнес и всегда на связи</p>
                </div>
                <div v-if="$route.path === '/1'">
                  <p data-v-7e5084da="" class="money_title">Поможем интегрироваться<br/>в Российский бизнес</p>
                </div>
              </div>
            </div>
            <div data-v-7e5084da="" class="col-md-4 col-sm-12"><div data-v-7e5084da="" class="tuqmoq"><img data-v-7e5084da="" src="../assets/icons/tuqmoq.svg" alt="404" class="tuqmoq_img"><div data-v-7e5084da=""><p data-v-7e5084da="" class="tuqmoq_title">Грамотно и с гарантией</p><p data-v-7e5084da="" class="tuqmoq_info">потому что специализированные юристы</p></div></div></div>
          </div>
        </div>
      </div>
      <section class="body_five">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="bissTable">
                <p class="bissTable_title">ЗАНИМАЙТЕСЬ БИЗНЕСОМ</p>
                <p class="bissTable_info">юридическую рутину отдайте специалистам</p>
                <div class="table-container">
                  <table class="table table-bordered" v-if="getDatabase">
                    <thead>
                    <tr>
                      <th class="thead_title" :class="{'active':col.prop === '2'}" v-for="(col, i) in business"
                          :key="i"
                      >{{ col.label }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in getDatabase.business" :key="i">
                      <td
                        :class="{'tbody_one':col.prop === '1', 'tbody_title':col.prop !== '1'}"
                        v-for="(col, index) in business"
                        :key="index"
                      >{{row[col.prop]}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="body">
        <div class="container">
          <div class="vxod" id="scroll-package">
            <h1 class="vxod_title">ЧТО ВХОДИТ В ПАКЕТ </h1>
            <p class="vxod_info">Решаем вопросы компании и вашего персонала!<br>Всего за 10 тысяч рублей в месяц</p>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">ЮРИДИЧЕСКИЕ КОНСУЛЬТАЦИИ</h1>
                        <p class="yuridik_box_info">Письменные, устные - без ограничений</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">АВТОДЕЛА</h1>
                        <p class="yuridik_box_info">
                          - Возврат прав<br>
                          - Претензия страховщику<br>
                          - Оспорить перегруз<br>
                          - Оспорить штраф с камеры</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">ПОДГОТОВКА ДОКУМЕНТОВ</h1>
                        <p class="yuridik_box_info">
                          Жалобы, заявления, претензии, ходайства, ответы, отзывы и много чего еще</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">НАЛОГОВЫЕ и ПФР СПОРЫ</h1>
                        <p class="yuridik_box_info">
                          - Ответы на требования<br>
                          - Оспаривание решений госорганов
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">ТРУДОВЫЕ СПОРЫ </h1>
                        <p class="yuridik_box_info">
                          - Ответы на претензии<br>
                          - Ответы госинстанциям</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">АРБИТРАЖ</h1>
                        <p class="yuridik_box_info">
                          - Составить Исковое заявление<br>
                          - Подготовить Отзыв на исковое заявление<br>
                          - Иск в третейский суд</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">ДОГОВОРЫ</h1>
                        <p class="yuridik_box_info">
                          - Анализ договора<br>
                          - Ответ на протокол разногласий<br>
                          - Составить договор</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title">ПРИСТАВЫ</h1>
                        <p class="yuridik_box_info">
                          - Заявление на исполнительный лист<br>
                          - Жалоба на судебных приставов<br>
                          - Приостановление исполнения<br>
                          - Вывод имущества</p>
                      </div>
                    </div>
                  </div>

                  <div v-if="isMobile" class="col-12">
                    <div class="yuridik">
                      <img src="../assets/icons/greengalka.svg" alt="404" class="yuridik_img">
                      <div class="yuridik_box">
                        <h1 class="yuridik_box_title"> НУЖНО ЧТО-ТО ЕЩЕ?</h1>
                        <p class="yuridik_box_info">
                          - Напишите нам!<br>
                          - Обсудим и согласуем
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Form style="margin: 60px 0 100px 0" :type="1" id="form1"/>
          <div style="margin-bottom: 40px" class="kak">
            <h1 class="kak_title">КАК ЭТО РАБОТАЕТ</h1>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="user">
                  <img src="../assets/icons/iconsuser.svg" class="user_img" alt="404">
                  <p v-if="$route.path === '/'" class="user_title">Закрепление персонального куратора</p>
                  <p v-else class="user_title">У вас есть персональный куратор</p>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="chat">
                  <img src="../assets/icons/chaticins.svg" class="chat_img " alt="404 ">
                  <p v-if="$route.path === '/'" class="chat_title ">Возникает вопрос - просто напишите его</p>
                  <p v-else class="chat_title ">Возникает вопрос - просто напишите его и куратор подберет специализированного юриста</p>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 ">
                <div class="pazzle ">
                  <img src="../assets/icons/puzzle.svg" class="pazzle_img " alt="404 ">
                  <p v-if="$route.path !== '/1'" class="pazzle_title ">Решим все вопросы, а оплата только в конце месяца</p>
                  <p v-else class="pazzle_title ">Точно знаем, что нужно и чем помочь бизнесу, входящему в РФ</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="body_seven">
        <div class="container">
          <div class="col-12">
            <div class="klient">
              <p class="klient_title">СРЕДИ НАШИХ КЛИЕНТОВ</p>
              <p v-if="$route.path === '/1'" class="klient_title" style="font-size: 20px;opacity: 0.8">УЖЕ БОЛЕЕ 30 КОМПАНИЙ ИЗ ЛДНР, ХЕРСОНА, МЕЛИТОПОЛЯ, БЕРДЯНСКА</p>
              <div id="demotwo" class="carousel slide" data-ride="carousel">
                <ul class="carousel-indicators">
                  <li data-target="#demotwo" data-slide-to="0" class="active"></li>
                  <li data-target="#demotwo" data-slide-to="1"></li>
                  <li data-target="#demotwo" data-slide-to="2"></li>
                </ul>
                <div class="carousel-inner vhs">
                  <div class="carousel-item active">
                    <div class="reklama">
                      <VueSlickCarousel v-bind="settings_clients">
                        <img src="../assets/icons/1.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/2.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/3.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/4.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/5.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/6.svg" class="reklama_one_img" alt="404">
                        <img src="../assets/icons/7.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/8.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/9.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/10.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/11.svg" class="reklama_two_img" alt="404">
                        <img src="../assets/icons/12.svg" class="reklama_two_img" alt="404">
                        <template #prevArrow="arrowOption">
                          <div class="custom-arrows-class custom-arrows-class-prev">
                            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                          </div>
                        </template>
                        <template #nextArrow="arrowOption">
                          <div class="custom-arrows-class">
                            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                          </div>
                        </template>
                      </VueSlickCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Form :type="2" style="margin-bottom: 50px"/>
      <div>
        <section style="margin-bottom: 70px" class="body_four">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div id="demo" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner" v-if="getDatabase">
                    <VueSlickCarousel v-bind="settings">
                      <div
                          v-for="(quest, i) in getDatabase.questBlock"
                          :key="i"
                          class="carousel-item">
                        <div class="vopros">
                          <div class="vopros_box">
                            <img src="../assets/icons/suroq.svg" class="vopros_box_img" alt="404">
                            <p class="vopros_box_title" v-html="quest.title"></p>
                          </div>
                          <h1 class="vopros_title" v-html="quest.subtitle"></h1>
                        </div>
                      </div>
                      <template #prevArrow="arrowOption">
                        <div class="custom-arrows-class custom-arrows-class-prev">
                          {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                        </div>
                      </template>
                      <template #nextArrow="arrowOption">
                        <div class="custom-arrows-class">
                          {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                        </div>
                      </template>
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Modal v-if="modal" @close="modal = false" :is-close="false" :is-policy="false">
      <template v-slot:body>
        <Form :type="3"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Modal from './notifications/Modal';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Form from "@/components/Form";
import CustomSlider from "@/components/CustomSlider";

export default {
  name: 'Home',
  components: { Form, VueSlickCarousel, Modal},
  data () {
    return {
      settings: {
        "dots": true,
        "arrows": true,
        "centerMode": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "initialSlide": 1,
              "centerMode": false,
            }
          }
        ]
      },
      settings_clients: {
        "dots": false,
        "arrows": false,
        "centerMode": true,
        "infinite": true,
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "autoplay": true,
        "speed": 7000,
        "autoplaySpeed": 200,
        "cssEase": "linear",
        "responsive": [
          {
            "breakpoint": 750,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
              "initialSlide": 1,
              "centerMode": false,
            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "initialSlide": 1,
              "centerMode": false,
            }
          }
        ]
      },
      business: [
        { label: '', prop: "1", type: 'string' },
        { label: 'Обслуживание у нас', prop: '2', type: 'string' },
        { label: 'Штатный юрист', prop: '3', type: 'string' },
        { label: 'Фрилансер', prop: '4', type: 'string' },
      ],
      modal: false
    }
  },
  computed: {
    getDatabase () {
      return this.$root.database.home
    },
    isMobile () {
      return window.innerWidth > 480
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/css/home.css";
@import "../assets/css/homeMedia.css";

.view {
  margin: 0;
}

@media (max-height: 700px) {
  .backone_body {
    height: calc(100vh - 88px)!important;
  }
  .head-button {
    bottom: 150px!important;
  }
}

.body {
  @media (max-width: 750px) {
    margin-bottom: 20px;
  }
}
.reklama{
  img {
    max-width: 150px;
  }
}
.body_four,
.reklama{
  @media (max-width: 1220px) {
    padding: 0 20px;
  }
}
.body_seven .container {
  @media (max-width: 1220px) {
    padding: 0;
  }
}
.body_seven .container .col-12 {
  @media (max-width: 1220px) {
    padding: 10px;
  }
}
.reklama_one {
  @media (max-width: 430px) {
    img {
      max-width: 100px;
      pointer-events: none;
    }
  }
}
.table-container {
  width: 100%;
  max-width: 100%;
  @media (max-width: 640px) {
    overflow: auto;
  }
}

.custom-arrows-class {
  background: black;
  background: url("../assets/icons/chevron-right.png") center no-repeat;
  height: 100px;
  opacity: 0.5;
  &:before {
    display: none;
  }
}
.custom-arrows-class-prev {
  background: url("../assets/icons/chevron-left.png") center no-repeat;
}

.backone_body {
  position: relative;
  height: calc(100vh - 350px);
}
.backone_footer {
  background-color: #989898;
  padding: 40px 0;
  &.wrap {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
  }
}
.thead_title {
  text-align: center;
  color: #4CA871;
}
.thead_title:hover {
  color: white;
}
.wrap {
  width: 100%;
  background: url("../assets/images/backone1.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.wrap-page-1 {
  width: 100%;
  background: url("../assets/images/page1.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.wrap-page-2 {
  width: 100%;
  background: url("../assets/images/page2.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.wrap-page-3 {
  width: 100%;
  background: url("../assets/images/page3.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;
}
.vopros {
  @media (max-width: 500px) {
    padding: 10px;
  }
  .vopros_box {
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 20px;
      }
    }
    .vopros_box_title {
      @media (max-width: 500px) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
.thead_title.active{
  text-align: center;
  background: #51D585;
  box-shadow: 0px 0px 24px rgb(56 163 54 / 50%), inset 0px 1px 0px rgb(0 0 0 / 15%), inset 1px 0px 0px rgb(0 0 0 / 15%);
  color: white;
  cursor: pointer;
}
.btn {
  padding: 15px 24px;
  background: #51D585;
  color: white!important;
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 171px;
  max-width: 300px;
  margin-top: 50px;
  @media (min-width: 700px) {
    font-size: 24px;
  }
}
.head-button {
  position: absolute;
  bottom: 100px;
  font-size: 36px;
  max-width: 400px;
  @media (max-width: 500px) {
    font-size: 26px;
    width: 100%;
    margin: 20px 0;
    bottom: 0px;
  }
}
.button-box {
  display: flex;
  align-items: flex-end;
}
.button-box .btn {
  max-height: 40px;
  margin-left: 40px;
  margin-bottom: 13px;
}
@media (max-width: 700px) {
  .button-box {
    flex-direction: column;
  }
  .btn {
    margin-top: 20px;
  }
}
</style>
