import {getDatabase, ref, set} from "firebase/database";
import firebase from "firebase/compat";

export default {
    async writeUserData(data) {
        // console.log('сохранение...')
        // const db = getDatabase();
        // await set(ref(db, '/'), {
        //     data
        // })
        await firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                console.log('сохранение...')
                const db = getDatabase();
                set(ref(db, '/'), {
                    data
                });
            } else {
                console.log('необходимо войти')
            }
        })
    }
}
