<template>
  <div class="referrals">
    <div class="wrap">
      <div data-v-7e5084da="" class="container"><div data-v-7e5084da="" class="row"><div data-v-7e5084da="" class="col-12"><div data-v-7e5084da="" class="besplatno"><p data-v-7e5084da="" class="besplatno_title">БЕСПЛАТНОЕ АБОНЕНТСКОЕ </p><p data-v-7e5084da="" class="besplatno_titleInfo"> ОБСЛУЖИВАНИЕ НАВСЕГДА!</p><p data-v-7e5084da="" class="besplatno_infoTwo" style="margin-bottom: 80px;">Да! Так бывает!</p></div></div></div></div>
    </div>
    <section class="body">
      <div class="container">
        <div class="bodyBox">
          <p class="bodyBox_title">
            ХОТИТЕ ОБСЛУЖИВАТЬСЯ У НАС
          </p>
          <p class="bodyBox_titletwo">
            ВСЕГДА И БЕСПЛАТНО<strong>?</strong>
          </p>
          <p class="bodyBox_info">
            Все просто! Мы будем добавлять 1 месяц бесплатного обслуживания:
          </p>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="network">
              <div class="network_box">
                <img src="../assets/icons/referal/vk-color 1.svg" class="network_box_vk" alt="404">
<!--                <img src="../assets/icons/referal/facebook-color 1.svg" class="network_box_fc" alt="404">-->
                <img src="../assets/icons/referal/youtube-color.1 1.svg" class="network_box_yu" alt="404">
<!--                <img src="../assets/icons/referal/instagram-color 1.svg" class="network_box_in" alt="404">-->
                <img src="../assets/icons/referal/tiktok-color 1.svg" class="network_box_tk" alt="404">
                <img src="../assets/icons/referal/yarus.png" class="network_box_tk" alt="404">
                <img src="../assets/icons/referal/zen-logo_text.png" class="network_box_tk" alt="404">
              </div>
              <p class="network_title">Если Вы разместите пост в любой социальной сети</p>
              <p class="network_info">Не забудьте прислать ссылку своему куратору для начисления бесплатного обслуживания 1 месяц за каждую сеть!</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="birbalo">
              <img src="../assets/icons/referal/birbalo.svg" class="birbalo_img" alt="404">
              <p class="birbalo_title">Рекомендуйте нас друзьям и бизнес-партнерам. Каждый подключившийся получает 1 месяц абонентского обслуживания + 1 месяц добавляется Вам.</p>
            </div>
          </div>
          <div class="col-12">
            <div class="usloviya">
              <h3 class="usloviya_title">Условия:</h3>
              <p class="usloviya_info">1. Для одной компании применимо размещение одного поста в одной социальной сети за исключением специальных акций</p>
              <div class="usloviya_pl">
                <p class="usloviya_pl_one">1.1. Принимаем ссылки на соцсети: Яндекс Дзен, Yarus, TikTok, Youtube, VK</p>
                <p class="usloviya_pl_two">1.2. Принимаются личные и бизнес аккаунты с числом подписчиков от 500 человек</p>
              </div>
              <div class="suroq">
                <img src="../assets/icons/referal/Group.svg" class="suroq_img" alt="404">
                <a href="#" class="suroq_title"> Условия и информация не являются офертой. Информацию можно уточнить у своего куратора или менеджеров Юридической экосистемы.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Referrals"
}
</script>

<style scoped lang="scss">
@import "../assets/css/referal.css";
@import "../assets/css/referalMedia.css";
.wrap {
  width: 100%;
  background: url("../assets/images/referal.png");
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  color: white!important;
}
.network_box_tk {
  max-width: 200px;
  max-height: 40px;
  &:nth-child(3) {
    margin-right: 12px;
  }
}
</style>
