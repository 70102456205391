<template>
    <div class="sceleton-container">
        <div class="sceleton-download">ЗАГРУЗКА
            <div class="circle-download">
                <svg class="spinner" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </div>
        </div>

        <div class="demo"></div>
    </div>
</template>

<script>
    export default {
        name: "Sceleton"
    }
</script>

<style scoped lang="scss">
    .sceleton-container {
        margin: auto;
        width: 60%;
        padding-top: 100px;
        .sceleton-download {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            font-size: 35px;
            height: 250px;
            text-align: center;
            left: calc(50% - 175px);
            top: calc(50% - 175px);
            z-index: 200;
            color: #313131;
            opacity: .5;
        }
    }
    .circle-download {
        .spinner {
            animation: rotate 2s linear infinite;
            z-index: 2;
            position: absolute;
            top: 50%;
            right: -60px;
            margin: -25px 0 0 -25px;
            width: 50px;
            height: 50px;

            & .path {
                stroke: hsl(210, 70, 75);
                stroke-linecap: round;
                animation: dash 1.5s ease-in-out infinite;
            }
        }
        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes dash {
            0% {
                stroke-dasharray: 1, 150;
                stroke-dashoffset: 0;
            }
            50% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -35;
            }
            100% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -124;
            }
        }
    }
    .demo:empty {
        width: 100%;
        height: 600px; /* change height to see repeat-y behavior */

        background-image:
                radial-gradient( circle 50px at 50px 50px, lightgray 99%, transparent 0 ),
                linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
                linear-gradient( lightgray 20px, transparent 0 ),
                linear-gradient( lightgray 20px, transparent 0 ),
                linear-gradient( lightgray 20px, transparent 0 ),
                linear-gradient( lightgray 20px, transparent 0 );

        background-repeat: repeat-y;

        background-size:
                0px 200px, /* circle */
                50px 200px, /* highlight */
                75% 200px,
                80% 200px,
                75% 200px,
                93% 200px;

        background-position:
                0 0, /* circle */
                0 0, /* highlight */
                120px 0,
                120px 40px,
                120px 80px,
                120px 120px;

        animation: shine 1s infinite;
    }

    @keyframes shine {
        to {
            background-position:
                    0 0,
                    100% 0, /* move highlight to right */
                    120px 0,
                    120px 40px,
                    120px 80px,
                    120px 120px;
        }
    }
</style>