<template>
  <div class="articles">
    <Sceleton v-if="!getDatabase"/>
    <section v-else class="body">
      <div class="container">
        <div class="row" v-if="getDatabase">
          <div
              v-for="(article, index) in getDatabase"
              :key="'articles-'+index"
              class="col-md-3 col-sm-12">
            <div class="cards">
              <div class="cards_img">
                <img :src="article.image" alt="404">
              </div>
              <p class="cards_title">{{article.name}}</p>
              <router-link
                  :to="{name: 'Cart', params: {id: index + 1}}"
                  class="cards_box_title"> Перейти <img src="../assets/icons/about/pere.svg" class="cards_box_img" alt="404"></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Sceleton from "@/components/Sceleton";
export default {
  name: "Articles",
  components: {Sceleton},
  computed: {
    getDatabase () {
      return this.$root.database.articles
    }
  }
}
</script>

<style scoped>
@import "../assets/css/articles.css";
@import "../assets/css/articlesMedia.css";
</style>
